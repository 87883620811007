
import Component, { mixins } from 'vue-class-component'
import { DataTableColumn } from '@/models/DataTableColumn'
import { formatFullName } from '@/utils/string'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import client from '@/services/user'
import HoldUpModal from '@/components/HoldUpModal.vue'
import AdminUserSidebar from '@/components/AdminUserSidebar.vue'
import { NAME_COLUMN_WIDTH } from '@/data/tableViews'
import { UserDetail } from '@/models/dto'
import auth from '@/store/modules/auth'
import DateMixin from '@/mixins/DateMixin'

@Component({
  components: { HoldUpModal, CUCollectionTable },
})
export default class AdminUsersList extends mixins(DateMixin) {
  tableView = client.tableView
  tableItemCount = 0

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  handleAddUser(): void {
    sidebar.push({
      component: AdminUserSidebar,
    })
  }

  columns: DataTableColumn[] = [
    {
      _t_id: '60a4d53f-45e5-4415-9837-fd4d3e82a648',
      text: 'Name',
      value: 'users-name',
      type: 'link',
      clickEvent: 'users:open-user-detail',
      computedText: (row: UserDetail): string => formatFullName(row),
      filterable: true,
      filterBySearch: true,
      filterAsIs: true,
      filterProp: 'fullName',
      filterType: 'contains',
      sortProp: 'lastName',
      width: NAME_COLUMN_WIDTH,
      unsetWidthOnBreakpoint: 'mdAndUp',
    },
    {
      _t_id: 'd97ff6a6-6203-45ce-a48e-b30073ce878d',
      text: 'Email',
      value: 'email',
      filterBySearch: true,
      filterProp: 'email',
    },
    {
      _t_id: '0fc2312a-4f90-4e50-adf1-a6b9012ff821',
      text: 'Group',
      value: 'groupName',
      filterBySearch: true,
      filterProp: 'groupName',
    },
    {
      _t_id: '9f621176-1d24-4536-8642-2a72f2c12733',
      text: 'Created On',
      value: 'createdOn',
      computedText: (row): string =>
        this.formatMediumDate(row.createdOn, { tz: auth.getUserTimeZone }),
    },
    {
      _t_id: '03fadeba-b77d-4777-93db-023808d7e1c8',
      text: 'Updated On',
      value: 'updatedOn',
      computedText: (row): string =>
        this.formatMediumDate(row.updatedOn, { tz: auth.getUserTimeZone }),
    },
    {
      _t_id: '833d0e9f-50d0-4f86-a510-9acfd3d5984e',
      text: 'Last Login',
      value: 'lastLogin',
      computedText: (row): string =>
        this.formatMediumDate(row.lastLogin, { tz: auth.getUserTimeZone }),
    },
    {
      _t_id: '44f9c00b-c12f-47cf-9316-1252a4fcf128',
      text: 'Company',
      value: 'companyName',
    },
  ]

  openUserDetail(row: UserDetail): void {
    sidebar.push({
      component: AdminUserSidebar,
      props: {
        userId: row.userId,
      },
    })
  }

  mounted(): void {
    EventBus.$on('users:open-user-detail', this.openUserDetail)
  }

  beforeDestroy(): void {
    EventBus.$off('users:open-user-detail', this.openUserDetail)
  }
}
