
import sidebar from '@/store/modules/sidebar'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { validateRules, validationRules } from '@/utils/rules'
import userClient from '@/services/user'
import roleClient from '@/services/role'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import { UserDetail, UserRequest } from '@/models/dto'
import { filter } from '@/utils/filter'
import auth from '@/store/modules/auth'
import { UserGroupIds, userGroups } from '@/data/userGroups'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: {
    AutocompleteAddress,
  },
})
export default class AdminUserSidebar extends Vue {
  @Prop({ required: false, default: null }) userId: number

  auth = auth
  rules = validationRules
  roles = []
  submitting = false
  userGroups = [
    ...userGroups,
    {
      groupId: 5,
      label: 'Admin + Driver',
      key: 'admin+driver',
    },
    {
      groupId: 6,
      label: 'User + Driver',
      key: 'user+driver',
    },
  ]

  user: UserDetail = new UserDetail()

  clear(): void {
    sidebar.pop()
  }

  handleUserRolesUpdate(): void {
    if (this.user.userRoleNames.length === 0) {
      this.user.userRoleNames = null
    }
  }

  handleUserGroupUpdate(): void {
    // Handling the admin + driver and user + driver
    // groups which have id 5 and 6 respectively
    this.user.treatAsDriver = this.user.groupId >= 4
    this.user.userRoleNames = this.getRoles(this.user.groupId % 4)
  }

  async submit(): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }

    this.submitting = true

    let groupId = this.user.groupId
    if (groupId > 4) {
      // If the group id is 5 or 6, set back to admin or user
      // group, roles should already be set accordingly
      groupId = groupId - 4
    }

    try {
      if (this.userId) {
        await userClient.update(this.userId, this.user)
        EventBus.$emit('snackbar:success', 'User updated successfully!')
      } else {
        const newUser: Partial<UserRequest> = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          companyId: auth.getCompanyId,
          email: this.user.email,
          groupId,
          userRoleNames: this.user.userRoleNames,
        }
        await userClient.create(newUser)
        EventBus.$emit('snackbar:success', 'User created successfully!')
      }
      sidebar.close()
    } catch {
      EventBus.$emit('snackbar:error', 'Error saving user')
    } finally {
      EventBus.$emit('refresh-tableview')
      this.submitting = false
    }
  }

  getRoles(groupId: number): string[] {
    const groups = this.user.treatAsDriver ? ['is_driver'] : []
    const admin = ['is_free_admin', 'is_paid_admin']
    const user = ['is_free_user', 'is_paid_user']
    if (this.user.company?.isAdminCompany) {
      admin.push('is_admin_admin')
      user.push('is_admin_user')
    }
    switch (groupId) {
      case UserGroupIds.ADMIN:
        return [...groups, ...admin, ...user]
      case UserGroupIds.USER:
        return [...groups, ...user]
      default:
        return groups
    }
  }

  async load(): Promise<void> {
    const filters = filter()
    const params = {
      page: 1,
      pageSize: -1,
      filters: filters.asQueryParams(),
      sorts: undefined,
    }
    const allRoles = await roleClient.tableView(params)
    this.roles = allRoles.data?.resultList

    if (this.userId) {
      const userResponse = await userClient.byId(this.userId)
      this.user = userResponse.data.user
      const userRoles = await userClient.roles(this.userId)
      this.user.userRoleNames = userRoles.data?.roles?.map(
        (role) => role.roleName
      )
    }
  }

  mounted(): void {
    this.load()
  }
}
